import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Container from "../components/Container"
import DotSection from "../components/DotSection"
import Hero from "../components/Hero"
import HeroLead from "../components/HeroLead"
import HeroTitle from "../components/HeroTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import SubTitle from "../components/SubTitle"
import Text from "../components/Text"
import Title from "../components/Title"
import links from "../content/links"
import metaImg from "../images/luottokorttipuhelin.svg"

export default (): JSX.Element => {
  return (
    <Layout>
      <SEO
        title="Esineiden Internet, IoT ja liitettävyys"
        description="Esineiden internet mahdollistaa fyysisen laitteesi kytkennän verkkoon."
        pageImage={metaImg}
        pageUrl={links.iot}
      />

      <section id="hero">
        <Hero>
          <HeroTitle>
            <h1>
              <span className="block">Esineiden internet,</span>
              <span className="block text-indigo-400">IoT</span>
            </h1>
          </HeroTitle>
          <HeroLead>
            Esineiden internet mahdollistaa fyysisen laitteesi kytkennän
            verkkoon ja verkkopalveluihin.
          </HeroLead>
        </Hero>
      </section>

      <section id="what-where-when">
        <DotSection dotPosition="top-right">
          <div className="grid md:grid-cols-2 md:gap-x-8">
            <div>
              <Title tag="h2">Mitä vain, missä vain, milloin vain</Title>
              <Text>
                Esineiden internet mahdollistaa fyysisen laitteesi kytkennän
                verkkoon ja verkkopalveluihin. Mahdollistamme laitteesi
                tuottaman mittausdatan kytkennän mihin vain. Pystyt siis omalta
                työpisteeltäsi tarkkailemaan laitteesi tuottamaa dataa ja
                tarkastamaan kaipaako laitteesi esimerkiksi huoltoa ja
                lähettämään huollon paikalle vain tarvittaessa. Siirrettäessä
                dataa verkon yli mahdollistamme laitteiden tarkkailun, jopa
                toiselta puolelta maapalloa.
              </Text>
              <Text>
                Toimitamme ymmärrettäviä ja visuaalisia verkko- ja
                mobiiliratkaisuita esineiden internettiin. Teemme ohjelmistoista
                yksinkertaisia ymmärtää, jolloin asetusten ja valikoiden
                selaaminen jää minimiin ja asiakas voi keskittyä itse palveluun
                ja ohjelmistoon.
              </Text>
              <Text>
                Nopean, kokonaisen järjestelmän toimituksen mahdollistamme
                käyttämällä valmiita moduuleita, joilla hallitaan ympäristön
                rakentaminen. Voimme keskittyä vain siihen mitä osaamme
                parhaiten, ohjelmointiin.
              </Text>
            </div>
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 mt-6 lg:mt-0">
              <StaticImage
                src="../images/5-left-trans-color.svg"
                alt="Profiili raketin ympärillä"
              />
            </div>
          </div>
        </DotSection>
      </section>

      <section id="structure" className="bg-gray-800 text-center">
        <Container>
          <div className="mb-5">
            <Title tag="h2" textColor="text-white">
              Rakenne
            </Title>
          </div>

          <SubTitle tag="p">Tyypillinen rakenne IoT ratkaisulle</SubTitle>
          <div className="grid md:grid-cols-3 mt-5">
            <div>
              <Text textColor="text-white">Mittalaite</Text>
            </div>
            <div>
              <Text textColor="text-white">Pilvipalvelin</Text>
            </div>
            <div>
              <Text textColor="text-white">Verkkopalvelu</Text>
            </div>
          </div>
        </Container>
      </section>

      <section id="where-to-start">
        <Container>
          <div className="grid lg:grid-cols-2 lg:space-x-5">
            <div className="mt-5">
              <Title tag="h3">Määrittely</Title>
              <Text>
                Kaikki alkaa määrittelystä. Mitä tietoja haluatte nähdä
                lopputuotteessa ja kenelle se näytetään? Tarvitseeko sivuston
                olla luettavissa liikkuvan työn tekijälle vai vain paikallaan
                olevalle tarkkaamolle? Onko laitteenne IoT valmis vai
                tarvitseeko se vielä määrittelyä fyysisessä rajapinnassa? Mitä
                väyliä laitteessa on käytössä, tarvitseeko suunnitella
                lisälaitteistoa? Onko teillä käytössä jo ohjelmia joihin
                tarvitsisi liittyä vai aloitetaanko ohjelmistokehitys alusta?
                Millaisessa ympäristössä laitetta käytetään? Kun näihin
                kysymyksiin saadaan vastattua laitteen määrittelyvaiheessa,
                lopputulos on paras mahdollinen.
              </Text>
            </div>
            <div className="mt-5">
              <Title tag="h3">Ohjelmisto</Title>
              <Text>
                Tarvitsit sitten yksisuuntaisen kuuntelun laitteellesi tai
                kaksisuuntaisen ohjattavuuden, me toteutamme sen. Arkkitehtuurin
                valinnassa huomioimme laitteen käyttämät resurssit, jos kyse on
                yksinkertaisemmasta laitteistossa, voimme käyttää kevyempää
                rautaa, jolloin käytämme matalamman tason kieliä.
                Monimutkaisimmissa järjestelmässä kehitämme järjestelmänne
                järeämmälle raudalle, sekä korkeamman tason ohjelmistoille.
                Tarjoamme IoT-lukuohjelmia meidän omilla palvelimilla, johon
                data lähetetään verkon yli turvallisesti. Tietoturva on
                muutenkin meille ykkösasia. Voimme vaihtoehtoisesti integroida
                laitteen jo teillä olemassa oleviin järjestelmiin.
              </Text>
            </div>
            <div className="mt-5">
              <Title tag="h3">Toteutus</Title>
              <Text>
                Rakennamme IoT-prototyypit valmiiden HW ratkaisujen pohjalle,
                jolloin vikasietoisuus kasvaa ja voimme keskittyä asian ytimeen,
                eli ohjelmointiin ja laitteen integrointiin järjestelmäänne.
                Valmis rauta mahdollistaa todella nopean toimituksen ja
                käyttöönoton asiakkaalle. Näin saamme pidettyä kehityskulut
                kurissa ja toiminnan ketteränä. Tuo ideasi meille niin
                ratkaistaan yhdessä siihen kohdistuvat ongelmat ja luodaan
                teillekin tulevaisuuden laitteisto.
              </Text>
            </div>
            <div className="mt-5">
              <Title tag="h3">Ylläpito ja jatkokehitys</Title>
              <Text>
                Kun laitteisto on pilotoitu ja toimitettu asiakkaalle, jatkamme
                hyvin usein työtä sen parissa. Lisäominaisuuksille on aina
                tarvetta ja asiakas huomaa tässä vaiheessa, mihin kaikkeen
                IoT-ratkaisuilla voidaan päästä. Kun pidämme ohjelman
                skaalattavana ja ammattimaisesti rakennettuna, ominaisuuksien
                lisäys on vaivatonta. Myös palvelun ulkoasun päivitys on
                arkipäivää meille.
              </Text>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}
